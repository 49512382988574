import './app.scss';
import 'lazysizes';
import htmx from 'htmx.org';
import blogOverviewFilter from './ts/blog-overview-filter';
import countdownBanner from './ts/countdown-banner';
import { initFlowbite } from 'flowbite';
// import 'intersection-observer';

// if you need react, just enable it by commenting in this line
// import react from './react/index';

(function () {
    // if you need react, just enable it by commenting in this line
    // react.init();

    const questionsFaq = document.getElementsByClassName('question-faq');
    if (questionsFaq.length) {
        import('./ts/faq').then((faq) => {
            faq.default.init();
        });
    }

    const menu = document.getElementById('header-menu');
    if (menu) {
        import('./ts/header').then((header) => {
            header.default.init();
        });
    }

    const aosElements = document.querySelectorAll('[data-aos]');
    if (aosElements.length) {
        import('./ts/aos').then((aos) => {
            aos.default.init();
        });
    }

    // Init all swiper if there are some
    const allSwiper = document.querySelectorAll(
        '.swiper-container'
    ) as NodeListOf<HTMLElement>;
    if (allSwiper.length) {
        import('./ts/swiper').then((swiper) => {
            allSwiper.forEach(function (swiperElement) {
                if (!swiperElement.dataset.swiperOptions) return;
                // Set swiper element and swiper options from data-attribute
                swiper.default.init(
                    swiperElement,
                    JSON.parse(swiperElement.dataset.swiperOptions)
                );
            });
        });
    }

    const forms = document.querySelectorAll('div[data-form-id]');
    if (forms.length) {
        import('./ts/form').then((form) => {
            form.default.init();
        });
    }

    const formSections = document.querySelectorAll(
        '.form-section'
    ) as NodeListOf<HTMLElement>;
    if (formSections.length) {
        import('./ts/form-download').then((formDownload) => {
            formSections.forEach((section) => {
                formDownload.default.init(section);
            });
        });
    }

    const calendlyButtons = document.querySelectorAll(
        '.calendly-button-container'
    ) as NodeListOf<HTMLElement>;
    if (calendlyButtons.length) {
        import('./ts/calendly-popup').then((calendlyPopup) => {
            calendlyButtons.forEach((button) => {
                calendlyPopup.default.init(button);
            });
        });
    }

    // no element request because element will be generated via js
    import('./ts/cookie-banner').then((cookieBanner) => {
        cookieBanner.default.init();
    });

    const youtubeVideoContainers = document.querySelectorAll(
        '.youtube-video-container'
    ) as NodeListOf<HTMLElement>;
    if (youtubeVideoContainers.length) {
        import('./ts/youtube-video').then((youtubeVideo) => {
            youtubeVideoContainers.forEach((container) => {
                youtubeVideo.default.init(container);
            });
        });
    }

    const referenceSliders = document.querySelectorAll(
        '.reference-slider'
    ) as NodeListOf<HTMLElement>;
    if (referenceSliders.length) {
        import('./ts/reference-slider').then((slider) => {
            referenceSliders.forEach((container) => {
                slider.default.init(container);
            });
        });
    }

    const heroHomeSections = document.querySelectorAll(
        '.hero-home, .hero-text'
    ) as NodeListOf<HTMLElement>;
    if (heroHomeSections.length) {
        import('./ts/hero-home-animation').then((heroHomeAnimation) => {
            heroHomeSections.forEach((section) => {
                heroHomeAnimation.default.init(section);
            });
        });
    }

    const useCasesSections = document.querySelectorAll(
        '.use-cases'
    ) as NodeListOf<HTMLElement>;
    if (useCasesSections.length) {
        import('./ts/use-cases').then((useCases) => {
            useCasesSections.forEach((section) => {
                useCases.default.init(section);
            });
        });
    }

    const countdownBannerElement = document.querySelector(
        '.countdown-banner'
    ) as HTMLElement;
    if (countdownBannerElement) {
        countdownBanner.init();
    }

    // this dummy is only here to force a regeneration of the app bundle hash
    // changing the variable's name doesn't affect functionality of the page
    // see https://github.com/vitejs/vite/issues/9583
    const dummy1 = 0;

    // push content down, so it's not hidden behind the banner/header
    const content = document.querySelector('.content') as HTMLElement;
    if (content) {
        const header = document.querySelector('header');
        let contentTranslateY = dummy1;
        if (header) contentTranslateY += header.clientHeight;
        if (countdownBannerElement) contentTranslateY += countdownBannerElement.clientHeight;
        content.style.paddingTop = (contentTranslateY) + 'px';
    }

    // const blogPostPages = document.querySelectorAll(
    //     '.blog-post'
    // ) as NodeListOf<HTMLElement>;
    // if (blogPostPages.length) {
    //     import('./ts/smooth-scrolling').then((smoothScrolling) => {
    //         blogPostPages.forEach((page) => {
    //             smoothScrolling.default.init(page);
    //         });
    //     });
    // }

    const filterButtons = document.querySelectorAll(
        '.filter-button-blog'
    ) as NodeListOf<HTMLElement>;
    if (filterButtons.length) {
        filterButtons.forEach(() => {
            blogOverviewFilter.init();
        });
    }

    htmx.on('htmx:afterSwap', function () {
        const filterButtons = document.querySelectorAll(
            '.filter-button-blog'
        ) as NodeListOf<HTMLElement>;
        if (filterButtons.length) {
            filterButtons.forEach(() => {
                blogOverviewFilter.init();
            });
        }
        initFlowbite();
    });
})();
